import type { SkinDefinition } from '@wix/editor-elements-panel/src/adapters/types';
import { addPrefix, prefixes, regular } from './common';
import { commonContainerSkinParamsUDP } from './containerSkinParams';
import {
  commonItemSkinParamsUDP,
  commonItemSkinParamsLayoutPanel,
} from './itemSkinParams';
import { commonSubItemSkinParamsUDP } from './subItemSkinParams';

export const ownDropdownMenuSkinParamsUDP: SkinDefinition = {};
export const commonDropdownMenuSkinParamsUDP: SkinDefinition = {
  ...commonContainerSkinParamsUDP,
  ...commonItemSkinParamsUDP,
  ...commonSubItemSkinParamsUDP,
};
export const dropdownMenuSkinParamsUDP: SkinDefinition = {
  ...commonDropdownMenuSkinParamsUDP,
  ...ownDropdownMenuSkinParamsUDP,
};
export const prefixedDropdownMenuSkinParamsUDP: SkinDefinition = addPrefix(
  dropdownMenuSkinParamsUDP,
  prefixes.dropdownMenu,
);

export const ownDropdownMenuSkinParamsLayoutPanel: SkinDefinition = {
  'container-vertical-padding': {
    type: 'SIZE',
    defaultValue: '0px',
    state: regular,
  },
  'container-horizontal-padding': {
    type: 'SIZE',
    defaultValue: '0px',
    state: regular,
  },
  'item-vertical-spacing': {
    type: 'SIZE',
    defaultValue: '0px',
    state: regular,
  },
  'item-horizontal-spacing': {
    type: 'SIZE',
    defaultValue: '0px',
    state: regular,
  },
  'sub-items-vertical-spacing-before': {
    type: 'SIZE',
    defaultValue: '0px',
    state: regular,
  },
  'sub-items-vertical-spacing-between': {
    type: 'SIZE',
    defaultValue: '0px',
    state: regular,
  },
  'sub-item-vertical-padding': {
    type: 'SIZE',
    defaultValue: '0px',
    state: regular,
  },
  'sub-item-horizontal-padding': {
    type: 'SIZE',
    defaultValue: '0px',
    state: regular,
  },
  'columns-number': {
    type: 'CSSString',
    state: regular,
    defaultValue: '2',
  },
  align: {
    type: 'CSSString',
    state: regular,
    defaultValue: 'start',
  },
  'item-align': {
    type: 'CSSString',
    state: regular,
    defaultValue: 'inherit',
  },
  'sub-item-align': {
    type: 'CSSString',
    state: regular,
    defaultValue: 'inherit',
  },
};

export const commonDropdownMenuSkinParamsLayoutPanel: SkinDefinition =
  commonItemSkinParamsLayoutPanel;
export const dropdownMenuSkinParamsLayoutPanel: SkinDefinition = {
  ...commonDropdownMenuSkinParamsLayoutPanel,
  ...ownDropdownMenuSkinParamsLayoutPanel,
};
export const prefixedDropdownMenuSkinParamsLayoutPanel: SkinDefinition =
  addPrefix(dropdownMenuSkinParamsLayoutPanel, prefixes.dropdownMenu);

export const dropdownMenuSkinParams: SkinDefinition = {
  ...dropdownMenuSkinParamsUDP,
  ...dropdownMenuSkinParamsLayoutPanel,
};
export const prefixedDropdownMenuSkinParams: SkinDefinition = {
  ...prefixedDropdownMenuSkinParamsUDP,
  ...prefixedDropdownMenuSkinParamsLayoutPanel,
};

const commonDropdownMenuSkinParams = {
  ...commonDropdownMenuSkinParamsUDP,
  ...commonDropdownMenuSkinParamsLayoutPanel,
};

export const attachedDropdownMenuStyleProperties = Object.fromEntries(
  Object.keys(commonDropdownMenuSkinParams).map(param => [param, 'initial']),
);

export const attachedDropdownMenuCssVariables = Object.fromEntries(
  Object.keys(commonDropdownMenuSkinParams).map(param => [
    `--${param}`,
    'initial',
  ]),
);
